<!--

	<MhDevGrid
		:doLog="true"
		:showOnHosts="['localhost', 'digiguide.local', 'digiguide-staging.opelvillen.de']"
		:show="_.get( userInfo, 'display_name' ) ? true : undefined"
		:columns="12"
		:color="'cyan'"
		:gridGap="'1em'"
		:maxWidth="'var(--app-content-maxWidth)'"
		:hPadding="'var(--app-content-hPadding)'"
		:opacity="0.35"
		:toggleKey="'g'"
	></MhDevGrid>

	2023-01-23	bugfix: in onAfterKeydown() war isValidTargetEl logik nicht korrekt
	2022-12-20	improvement: added ignoredSelectors to onAfterKeydown() to prevent grid toggle in inputs etc.
	2021-11-12	bugfix: fixed h padding settings. they moved from elmStyles to elmInnerStyles
	2021-11-12	improvement: added props showOnHosts doLog show to control conditional visibility
	2021-11-12	improvement: removed 'forceVisibleOnMount'

	2021-10-12	improvement: added maxWidth and hPadding prop
	2021-10-12	improvement: grid is hidden by default
	2021-09-28	improvement: added esc-key hides grid
	2021-09-22	init

-->

<template>
	<div class="MhDevGrid" :class="elmClasses" :style="elmStyles" v-if="doShow">
		<div class="MhDevGrid__inner" :class="innerElmClasses" :style="elmInnerStyles">
			<div class="MhDevGrid__col" v-for="(column, index) in columns" :key="index"
			     :style="colStyles">
				<!--{{index}}-->
			</div>
		</div>
	</div>
</template>

<script>
	import { startsWith } from 'lodash'

	export default {
		name: 'MhDevGrid',
		components: {},
		mixins: [],
		props: {
			columns: {
				type     : [String, Number],
				default  : 12,
				required : false,
			},
			gridGap: {
				type     : [String, Number],
				default  : '1em',
				required : false,
			},
			maxWidth: {
				type     : [String],
				default  : '',
				required : false,
			},
			hPadding: {
				type     : [String],
				default  : '',
				required : false,
			},
			color: {
				type     : [String],
				default  : 'cyan',
				required : false,
			},
			opacity: {
				type     : [Number],
				default  : 0.35,
				required : false,
			},
			toggleKey: {
				type     : [String],
				default  : 'g',
				required : false,
			},
			showOnHosts: {
				type     : [Array, String],
				default  : ()=>{ return [] },
				required : false,
			},
			show: {
				type     : [Boolean, String],
				default  : '',
				required : false,
			},
			doLog: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data() {
			return {
				store :{
					isVisible : false,
					version : 1,
				},
				isInit : false,
			}
		},
		watch: {
			store: {
				handler: function( to, from ) {
					// save store
					const parsed = JSON.stringify( this.store )
					localStorage.setItem('MhDevGrid', parsed )
				},
				deep: true,
			},
			doShow: {
				handler: function( to, from ) {
					if( to && !this.isInit ) this.init()
				},
				immediate: true,
				deep: true,
			},
		},
		computed: {
			elmClasses() {
				let classes = []

				if( this.store.isVisible ) classes.push( this.$options.name + '--isVisible' )

				return classes
			},
			innerElmClasses() {
				let classes = []

				//if( this.store.isVisible ) classes.push( this.$options.name + '--isVisible' )

				return classes
			},
			elmStyles() {
				let styles = {}

				//styles.paddingLeft = this.hPadding
				//styles.paddingRight = this.hPadding
				//styles.maxWidth = this.maxWidth

				return styles
			},
			elmInnerStyles() {
				let styles = {}

				styles.gridTemplateColumns = 'repeat('+this.columns+', 1fr)';
				styles.gridGap = this.gridGap
				styles.opacity = this.opacity
				styles.maxWidth = this.maxWidth
				styles.paddingLeft = this.hPadding
				styles.paddingRight = this.hPadding

				return styles
			},
			colStyles(){
				let styles = {}

				styles.backgroundColor = this.color

				return styles
			},
			doShow(){
				const validHosts  = this.showOnHosts
				const currentHost = this.hostname
				const doLog       = this.doLog
				let doShow        = false

				// show if currentHost is in given hosts
				if( this._.isArray( validHosts ) ){
					if( this._.includes( validHosts, currentHost ) ) doShow = true
				}
				// show if currentHost is given host
				else if( this._.isString( validHosts ) ){
					if( validHosts == currentHost ) doShow = true
				}
				// show by default, no host as prop given
				else if( !validHosts ) {
					doShow = true
				}

				if( this.show === true ) doShow = true
				if( this.show === false ) doShow = false

				if( doLog ) {
					console.group( this.$options.name, '• doShow' )
					console.log( 'show:', this.show )
					console.log( 'validHosts:', validHosts )
					console.log( 'currentHost:', currentHost )
					console.log( 'doShow:', doShow )
					console.groupEnd()
				}

				return doShow
			},
			hostname() {
				return window.location.hostname
			},
		},
		methods: {
			onAfterKeydown( e ){
				const targetEl = e.target
				const ignoredSelectors = ['input', 'textarea', '[contenteditable]']
				let isValidTargetEl = true

				ignoredSelectors.forEach((selector)=>{
					if( targetEl.matches( selector ) ) isValidTargetEl = false
				})

				if( isValidTargetEl && e.key === this.toggleKey.toLowerCase() ){
					this.store.isVisible = !this.store.isVisible
				}
				if( isValidTargetEl && e.key === 'Escape' ){
					this.store.isVisible = false
				}
			},
			init(){
				window.addEventListener('keydown', this.onAfterKeydown)

				// restore saved store
				const savedStore = localStorage.getItem('MhDevGrid')
				if( savedStore ) this.store = JSON.parse( savedStore )
			},
		},
		created(){
			},
		mounted(){
			window.addEventListener('keydown', this.onAfterKeydown)
			console.log( this.$options.name, '• doLog', this.doLog)
		},
		destroyed(){
   			window.removeEventListener('keydown', this.onAfterKeydown)
 		},
	}
</script>

<style lang="less">
	.MhDevGrid { // debug
		//background-color: fade( red, 20 );
		//&__inner { background-color: fade( red, 20 ); }
		//&__col { outline: 1px solid red; }
	}
	.MhDevGrid { // layout
		position: fixed;
		top: 0; left: 0; right: 0;
		height: 100vh;
		z-index: 1000;
		pointer-events: none;

		&__inner {
			margin: 0 auto;
			display: grid;
			height: inherit;
			width: 100%;
			max-width: 100%;
		}
	}
	.MhDevGrid { // styling
		transition: all 0.1s ease;
		opacity: 0;

		&--isVisible { opacity: 1; }
	}
</style>
