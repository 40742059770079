<!--

	<MhDevPanel
		:doLog="true"
		:showOnHosts="['localhost', 'digiguide.local', 'digiguide-staging.opelvillen.de']"
		:show="_.get( userInfos, 'display_name' ) ? true : undefined"
		:nonce="'0f153afeaa'"
	></MhDevPanel>

	2022-10-07	improvement: added excludedTypes to filter out 'nav_menu_item' which always response with 'not allowed'
	2022-10-07	improvement: item titles are now parsed via v-html
	2022-03-07	bugfix: mit dem letzten wp updates ist ein fehler mit dem type "nav_menu_item" aufgetaucht. Deswegen würde jetzt abgefragt, ob der type auch wirklich items hat.
	2022-01-25	improvement: added conditional v-if to $i18n block
	2021-11-14	improvement: added Login-Form
	2021-11-14	improvement: added 'X-WP-Nonce' header if given via prop 'nonce'
	2021-11-12	improvement: added props showOnHosts doLog show to control conditional visibility
	2021-11-11	bugfix: grid verursachte einen overflow auf xs durch den span--2
	2021-11-04	bugfix: doShow=false hat nicht die componente versteckt. das ist jetzt der fall.
	2021-10-31	improvement: added routes infos as table
	2021-10-31	improvement: added $mq breakpoints
	2021-10-31	improvement: added $i18n infos
	2021-10-31	improvement: use guid.rendered as href for attachments
	2021-10-29	improvement: added quickLinks as array-prop

-->

<template>
	<div class="MhDevPanel" v-if="doShow">
		<div class="MhDevPanel__inner">

			<!-- Base Links -->
			<!-- App Routes -->
			<div class="MhDevPanel__blocks">
				<div class="MhDevPanel__block" v-if="quickLinks.length">
					<div class="MhDevPanel__blockHeader">
						Wordpress-Login
					</div>
					<div class="MhDevPanel__blockBody">
						<form @submit="submitLoginForm" method="post">
							User: <input type="text" name="user" v-model="loginForm.user" /><br>
							Pass: <input type="password" name="pass" v-model="loginForm.pass" /><br>
							<button>OK</button>
						</form>
						<div v-if="loginForm.errorMsg">
							<hr/>
							<a @click="loginForm.errorMsg = ''">close</a>
							<div v-html="loginForm.errorMsg"></div>
						</div>
					</div>
				</div>
				<div class="MhDevPanel__block" v-if="quickLinks.length">
					<div class="MhDevPanel__blockHeader">
						Quick-Links
					</div>
					<div class="MhDevPanel__blockBody">
						<router-link class="MhDevPanel__blockLink"
							:to="link.href"
							v-for="(link, index) in quickLinks"
							:key="index"
						>{{link.label}}</router-link>
					</div>
				</div>
				<div class="MhDevPanel__block" v-if="routes.length">
					<div class="MhDevPanel__blockHeader">
						Routes
					</div>
					<div class="MhDevPanel__blockBody">
						<!--
						<div class="MhDevPanel__blockLink" v-for="(route, index) in $router.options.routes" :key="'r'+index">
							{{route.name}} • {{route.path}}
						</div>
						-->
						<table>
							<thead>
								<tr>
									<th>name</th>
									<th>path</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(route, index) in $router.options.routes" :key="'r'+index">
									<td>{{route.name}}</td>
									<td>{{route.path}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="MhDevPanel__block">
					<div class="MhDevPanel__blockHeader">
						$mq breakpoints
					</div>
					<div class="MhDevPanel__blockBody">
						<pre name="$root.mqBreakpoints">{{$root.mqBreakpoints}}</pre>
						<!--
						-->
					</div>
				</div>
				<div class="MhDevPanel__block" v-if="has$i18n">
					<div class="MhDevPanel__blockHeader">
						$i18n
					</div>
					<div class="MhDevPanel__blockBody">
						<pre name="$i18n.locale">{{$i18n.locale}}</pre>
						<pre name="$i18n.availableLocales">{{$i18n.availableLocales}}</pre>
						<pre name="$i18n.fallbackLocale">{{$i18n.fallbackLocale}}</pre>
						<!--
						-->
					</div>
				</div>
				<!-- CSS-Vars -->
				<div class="MhDevPanel__block MhDevPanel__block--span-2" v-if="_.size( cssVars )">
					<div class="MhDevPanel__blockHeader">
						CSS-Vars
					</div>
					<div class="MhDevPanel__blockBody">
						<table>
							<thead>
								<tr>
									<th>key</th>
									<th>value</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(value, key) in cssVars" :key="key">
									<td>{{key}}</td>
									<td>{{value}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- Wordpress PostTypes and their posts -->
			<div class="MhDevPanel__blocks">
				<div class="MhDevPanel__block" v-for="(postType, i) in types" :key="i">
					<div class="MhDevPanel__blockHeader">
						{{postType.name}} • {{ postType.slug}} • {{postType.items.length}} items
					</div>
					<div class="MhDevPanel__blockBody">
						<a class="MhDevPanel__blockLink" v-for="(item, j) in postType.items" :key="'j'+j" :title="item.link" :href="item.type === 'attachment' ? _.get( item, 'guid.rendered' ) : item.link" v-html="_.get( item, 'title.rendered' )"></a>
						<br v-if="!postType.items.length" />
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	// could pass in an array of specific stylesheets for optimization
	function getAllCSSVariableNames( styleSheets = document.styleSheets){
		var cssVars = [];
		// loop each stylesheet
		for(var i = 0; i < styleSheets.length; i++){
			// loop stylesheet's cssRules
			try{ // try/catch used because 'hasOwnProperty' doesn't work
				for( var j = 0; j < styleSheets[i].cssRules.length; j++){
					try{
					// loop stylesheet's cssRules' style (property names)
					for(var k = 0; k < styleSheets[i].cssRules[j].style.length; k++){
						let name = styleSheets[i].cssRules[j].style[k];
						// test name for css variable signiture and uniqueness
						if(name.startsWith('--') && cssVars.indexOf(name) == -1){
							cssVars.push(name);
						}
					}
					} catch (error) {
						//
					}
				}
			} catch (error) {
				//
			}
		}
		return cssVars;
	}
	function getElementCSSVariables( allCSSVars, element = document.body, pseudo){
		var elStyles = window.getComputedStyle(element, pseudo)
		var cssVars = {}
		for( var i = 0; i < allCSSVars.length; i++ ){
			let key = allCSSVars[i]
			let value = elStyles.getPropertyValue(key)
			if( value ) cssVars[key] = value
		}
		return cssVars
	}

	export default {
		name: 'MhDevPanel',
		components: {},
		props: {
			quickLinks: {
				type     : [Array],
				default  : ()=>{ return [] },
				required : false,
			},
			showOnHosts: {
				type     : [Array, String],
				default  : ()=>{ return [] },
				required : false,
			},
			show: {
				type     : [Boolean, String],
				default  : '',
				required : false,
			},
			doLog: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			nonce: {
				type     : [String],
				default  : undefined,
				required : false,
			},
		},
		data(){
			return {
				types : {},
				cssVars : {},
				getCssVarsIntervalDelay : 2000,
				getCssVarsIntervalFn : null,
				isInit: false,
				excludedTypes: [
					'nav_menu_item',
				],
				loginForm : {
					user : '',
					pass : '',
					errorMsg : '',
				}
			}
		},
		watch: {
			doShow: {
				handler: function( to, from ) {
					if( to && !this.isInit ) this.init()
				},
				immediate: true,
				deep: true,
			},
		},
		computed: {
			doShow(){
				const validHosts  = this.showOnHosts
				const currentHost = this.hostname
				const doLog       = this.doLog
				let doShow        = false

				// show if currentHost is in given hosts
				if( this._.isArray( validHosts ) ){
					if( this._.includes( validHosts, currentHost ) ) doShow = true
				}
				// show if currentHost is given host
				else if( this._.isString( validHosts ) ){
					if( validHosts == currentHost ) doShow = true
				}
				// show by default, no host as prop given
				else if( !validHosts ) {
					doShow = true
				}

				if( this.show === true ) doShow = true
				if( this.show === false ) doShow = false

				if( doLog ) {
					console.group( this.$options.name, '• doShow' )
					console.log( 'show:', this.show )
					console.log( 'validHosts:', validHosts )
					console.log( 'currentHost:', currentHost )
					console.log( 'doShow:', doShow )
					console.groupEnd()
				}

				return doShow
			},
			routes(){
				//let routes = this.$router.options.routes
				let routes = this._.get( this, '$router.options.routes', [] )
				let _routes = []

				routes.forEach( (route)=>{
					if( this._.has( route, 'name') ) _routes.push( route )
					//console.log('route:', route)
				})

				return _routes
			},
			hostname(){
				return window.location.hostname
			},
			has$i18n(){
				return this.$i18n ? true : false
			},
		},
		methods: {
			async fetchTypes(){
				let args = {
					method: 'GET',
					headers: {},
				}

				if( this.nonce ) args.headers['X-WP-Nonce'] = this.nonce

				let response = await fetch( '/wp-json/wp/v2/types', args )
				let parsed = await response.json()

				// walk the fetched types
				// filter out "wp_*"
				// filter out excludedTypes
				// fetch every type
				this._.forEach( parsed, (v, k)=>{
					if( k.indexOf('wp_') === -1 && !this.excludedTypes.includes(k) ){
						//console.log(`${k}`)
						v.items = []

						this.$set( this.types, k, v ) // $set is needed, vue wont update without
						this.fetchType( k )
					}
				})
			},
			async fetchType( typeKey ){
				let type = this.types[typeKey]
				let args = {
					method: 'GET',
					headers: {},
				}

				if( this.nonce ) args.headers['X-WP-Nonce'] = this.nonce

				let response = await fetch( '/wp-json/wp/v2/' + type.rest_base, args )
				let parsed = await response.json()

				if( this._.isArray( parsed ) ) this.types[typeKey].items = parsed
			},
			async submitLoginForm( e ){
				const form = e.target
				const user = this._.trim( this.loginForm.user )
				const pass = this._.trim( this.loginForm.pass )
				const canFetch = user && pass ? true : false

				console.group( this.$options.name, '• submitLoginForm() • fetch' )
				console.log('e:', e)
				console.log('form:', form)
				console.log('user:', user)
				console.log('pass:', pass)
				console.log('canFetch:', canFetch)
				console.groupEnd()

				e.preventDefault()
        		e.stopPropagation()

				if( canFetch ){
					this.loginForm.errorMsg = ''

					fetch('/wp-json/mh/v1/login', {
						body : JSON.stringify({
							user : this.loginForm.user,
							pass : this.loginForm.pass,
						}),
						method : 'post',
					})
					.then( response => response.json() )
					.then( data => {

						const wasSuccess = this._.has( data, 'result.id' )
						const hasError = this._.has( data, 'result.error' )

						console.group( this.$options.name, '• submitLoginForm() • callback' )
						console.log('data:', data)
						console.log('wasSuccess:', wasSuccess)
						console.log('hasError:', hasError)
						console.groupEnd()

						if( hasError ){
							this.loginForm.errorMsg = data.result.error
						}

						// force chrome to show "save password" dialog
						// which wont normally show up cause of ajax submit
						// see: https://gist.github.com/mkurz/75863ad10c70e519d642#file-success-html-L17-L20
						if( wasSuccess ){
							form.style.display = 'none'
							history.replaceState( { success: true }, 'title' )
							setTimeout( ()=>{ form.style.display = '' }, 25 )
						}
					})
				}
			},
			init(){
				this.fetchTypes()

				clearInterval( this.getCssVarsIntervalFn )

				this.getCssVarsIntervalFn = setInterval(()=>{
					const cssVarNames = getAllCSSVariableNames()
					const cssVars = getElementCSSVariables( cssVarNames, document.documentElement )

					this.cssVars = cssVars

				}, this.getCssVarsIntervalDelay)
			}
		},
		created(){},
		mounted(){},
		destroyed(){
   			clearInterval( this.getCssVarsIntervalFn )
 		},
	}
</script>

<style lang="less">
	.MhDevPanel {
		position: relative;
		padding: 1rem;
		padding-bottom: 2rem;
		font-size: 13px;
		line-height: 1.3em;
		background-color: lighten( cyan, 30 );
		color: black;

		&__inner {}

		.routerLink--exactActive {
			//font-weight: bold;
			color: red;
		}

		&__blocks {
			display: grid;
			grid-gap: 0.5em;
			//grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

			& + & { margin-top: 0.5em; }
		}
		&__block {
			border: 1px solid fade( black, 25 );
			display: flex;
			flex-direction: column;

			&Header {
				padding: 0.25rem;
				background-color: fade(white, 25);
				background-color: fade(black, 10);
				border-bottom: 1px solid fade( black, 25 );
				font-weight: bold;
			}
			&Body {
				flex-grow: 1;
				padding: 0.25rem;
				background-color: fade(black, 20);
			}

			&Link {
				display: block;
				padding: 0.25em 0;
			}
			&Link:not(:last-child) {
				border-bottom: 1px dotted fade(black, 25);
			}
			&Link:hover {
				background-color: fade( black, 5 );
			}
		}

		@media screen and (min-width:750px) {
			&__block--span-2 { grid-column: span 2; }
		}
	}

	.MhDevPanel { // table styling
		table { table-layout: fixed; border-collapse: collapse; width: 100%; }
		thead tr { border-bottom: 1px solid fade(black, 50); }
		thead th { opacity: 0.35; font-variant: small-caps; letter-spacing: 0.05em; }
		tbody tr:not(:last-child) { border-bottom: 1px dotted fade(black, 25); }
		td, th { xxfont-weight: 400; text-align: left; padding: 0.25em 0.5em 0.25em 0; }
		//tr > *:first-child { min-width: 33%; } // klappt das wirklich immer?
	}
</style>
