<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhMailjet2 :openBtnSelector="'.openMailjetModal'"></MhMailjet2>


	2023-02-06	init

-->

<template>
	<div class="MhMailjet" :class="elmClasses">
		<MhModal :show="showModal" @close="showModal = false">
			<template slot="header">
				Newsletter
			</template>
			<template slot="closeButton">
				<span>×</span>
			</template>
			<template slot="body">
				<iframe
					v-iframeResize="{ log: true }"
					data-w-type="embedded"
					frameborder="0"
					scrolling="yes"
					marginheight="0"
					marginwidth="0"
					src="https://mailjet.opelvillen.de/wgt/x7g3t/sij/form?c=e4822bfa"
					width="100%"
				></iframe>
			</template>
			<!--
			<template slot="footer">
				footer
			</template>
			-->
		</MhModal>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhModal from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhModal/v2/MhModal.vue'
	import iframeResize from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/directives/iframeResize/v1/iframeResize.directive.js'
	import is from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/helper/is.js'

	export default {
		name: 'MhMailjet',
		components: {
			MhModal,
		},
		directives: {
			iframeResize,
		},
		mixins: [],
		props: {
			openBtnSelector: {
				type: [String],
				default: '.openMhMailjet',
				required: false,
			},
		},
		data() {
			return {
				showModal: false,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			/*
			eventBusHandler( e, doLog = false ){
				const target = e.target

				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • eventBusHandler( e )')
					console.log('e:', e)
					console.log('target:', target)
					console.groupEnd()
				}

				window.mjOpenPopin(e, target)
			},
			*/
			linkClickHandler( e, doLog = true ){ // this opens the modal if the class of the link matches
				const target = e.target
				const doAction = is( target, this.openBtnSelector )
				//const doAction = target.classList.contains( this.triggerClass )

				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • linkClickHandler( e )', doAction)
					console.log('e:', e)
					console.log('target:', target)
					console.log('----')
					console.log('doAction:', doAction)
					console.groupEnd()
				}

				if( doAction ){
					this.showModal = true
				}
			},
		},
		created(){ // add click handler
			const body = document.querySelector('body')

			body.addEventListener('click', this.linkClickHandler)

			//EventBus.$on('mailjet-openWidget', this.eventBusHandler)
		},
		mounted( doLog = false ){ // load mailjet lib
			if( doLog ){
				console.group( this.$options.name, '• mounted()')
				console.groupEnd()
			}
		},
		beforeDestroy(){ // remove click handler
			const body = document.querySelector('body')

			body.removeEventListener('click', this.linkClickHandler)

			//EventBus.$off('mailjet-openWidget', this.eventBusHandler)
		},
		destroyed(){},
	}
</script>

<style lang="less">
	.MhMailjet {}
</style>
