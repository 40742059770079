export default {
	de : {
		placeholder : {},
		nav : {},
		title : {},
		button : {
			menu : 'Menü',
			readMore : 'Weiterlesen',
			readLess : 'Einklappen',
		},
		message : {}
	},
	en : {
		placeholder : {},
		nav : {},
		title : {},
		button : {
			menu : 'menu',
			readMore : 'Read more',
			readLess : 'Read less',
		},
		message : {}
	},
	es : {
		placeholder : {},
		nav : {},
		title : {},
		button : {
			menu : 'menú',
			readMore : 'seguir leyendo',
			readLess : 'cerrar',
		},
		message : {}
	},
}
