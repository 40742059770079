import Vue from 'vue'
import Router from 'vue-router'
import DgHomeView from '@/views/DgHomeView.vue'

Vue.use(Router)

export default new Router({
	base: '/',
	mode: 'history',
	linkActiveClass : 'routerLink--active',
	linkExactActiveClass : 'routerLink--exactActive',
	routes: [
		{
			path: '/',
			redirect: '/de/',
		},
		{
			path: '/dev/',
			name: 'DgDevView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "DgDevView" */ '@/views/DgDevView.vue' )
		},
		{
			path: '/:lang/',
			name: 'DgHomeView',
			pathToRegexpOptions: { strict: true },
			component: DgHomeView,
		},
		{
			path: '/:lang/guide/:slug/',
			name: 'DgGuideView',
			pathToRegexpOptions: { strict: true },
			component: () =>
				import( /* webpackChunkName: "DgGuideView" */ '@/views/DgGuideView.vue')
		},
		{
			path: "*",
			name: 'NotFoundView',
			component: () =>
				import( /* webpackChunkName: "NotFoundView" */ '@/views/NotFoundView.vue')
		},
	]
})
