<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhAspectRatioWrapper :aspectRatio="'16:9'">
			<MhImage
				:imageObject="firstImage"
				:mode="'cover'"
			></MhImage>
		</MhAspectRatioWrapper>

	2023-01-27	init

-->

<template>
	<div class="MhAspectRatioWrapper" :class="elmClasses">
		<div class="MhAspectRatioWrapper__ratioHelper" :style="ratioHelperStyles"></div>
		<div class="MhAspectRatioWrapper__inner"><slot></slot></div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'MhAspectRatioWrapper',
		components: {},
		mixins: [],
		props: {
			aspectRatio: {
				type: [String],
				default: '16:9',
				required: false,
				validator( value ){
					//console.log( value )
					return value.includes(':')
				}
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			ratioHelperStyles(){
				const aspectRatio = this.aspectRatio.replace(/\s+/g, '').trim()
				let styles = {}

				const width = parseInt( aspectRatio.split(':')[0] )
				const height = parseInt( aspectRatio.split(':')[1] )
				const paddingBottom = ( height / width ) * 100

				styles.paddingBottom = paddingBottom + '%'

				return styles
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.MhAspectRatioWrapper { // debug
		[showBorders4] & {
			.outline( green );
			.label( 'MhAspectRatioWrapper' );
			&__ratioHelper { background-color: fade( blue, 20 ); }
		}
	}
	.MhAspectRatioWrapper { // vars
		//
	}
	.MhAspectRatioWrapper { // layout
		position: relative;

		&__inner {
			position: absolute;
			inset: 0;
			overflow: hidden;
		}
	}
	.MhAspectRatioWrapper { // styling
		//
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
