/**
 * Vanilla JS equivalent of jQuery .is()
 *
 * @param elm : element
 * @param selector : selector is an element, an array or elements, or a string selector for `document.querySelectorAll`
 * @returns boolean
 */

function is( elem, selector ){
	if(selector.nodeType){
		return elem === selector;
	}

	var qa = (typeof(selector) === 'string' ? document.querySelectorAll(selector) : selector),
		length = qa.length,
		returnArr = [];

	while(length--){
		if(qa[length] === elem){
			return true;
		}
	}

	return false;
}

export default is;