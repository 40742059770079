/**
 * add to your vue-file
 * 		import iframeResize from 'path/to/iframeResize.directive.js'
 *
 * bind directive to your component:
 * 		directives: { iframeResize }
 *
 * in your template:
 * 		<iframe v-iframeResize="{ log: true }" ... ></iframe>
 *
 */

import iframeResize from './js/iframeResizer'

export default {
	bind: function(el, { value = {} }) {
		//console.log('iframeResize • bind')

		el.addEventListener('load', () => iframeResize(value, el))
	},
	unbind: function (el) {
		//console.log('iframeResize • unbind')

		el.iFrameResizer.removeListeners()
	}
};